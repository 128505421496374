import styles from '../css/app.css';
//import logo from '../img/logo.svg';

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faPhone } from '@fortawesome/pro-solid-svg-icons';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { faLocationArrow } from '@fortawesome/pro-solid-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
library.add(faPhone, faEnvelope, faLocationArrow, faFacebookSquare, faInstagram, faTwitter)
dom.watch()


import 'lazysizes';



/* IE Polyfull */

if (typeof Object.assign != "function") {
  Object.defineProperty(Object, "assign", {
    value: function assign(target, varArgs) {
      "use strict"
      if (target == null) {
        throw new TypeError("Cannot convert undefined or null to object")
      }
      var to = Object(target)
      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index]
        if (nextSource != null) {
          for (var nextKey in nextSource) {
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey]
            }
          }
        }
      }
      return to
    },
    writable: true,
    configurable: true
  })
}

if (!Array.from) {
  Array.from = (function() {
    var toStr = Object.prototype.toString
    var isCallable = function(fn) {
      return typeof fn === "function" || toStr.call(fn) === "[object Function]"
    }
    var toInteger = function(value) {
      var number = Number(value)
      if (isNaN(number)) {
        return 0
      }
      if (number === 0 || !isFinite(number)) {
        return number
      }
      return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number))
    }
    var maxSafeInteger = Math.pow(2, 53) - 1
    var toLength = function(value) {
      var len = toInteger(value)
      return Math.min(Math.max(len, 0), maxSafeInteger)
    }

    return function from(arrayLike) {
      var C = this
      var items = Object(arrayLike)
      if (arrayLike == null) {
        throw new TypeError(
          "Array.from requires an array-like object - not null or undefined"
        )
      }
      var mapFn = arguments.length > 1 ? arguments[1] : void undefined
      var T
      if (typeof mapFn !== "undefined") {
        if (!isCallable(mapFn)) {
          throw new TypeError(
            "Array.from: when provided, the second argument must be a function"
          )
        }
        if (arguments.length > 2) {
          T = arguments[2]
        }
      }
      var len = toLength(items.length)
      var A = isCallable(C) ? Object(new C(len)) : new Array(len)
      var k = 0
      var kValue
      while (k < len) {
        kValue = items[k]
        if (mapFn) {
          A[k] =
            typeof T === "undefined"
              ? mapFn(kValue, k)
              : mapFn.call(T, kValue, k)
        } else {
          A[k] = kValue
        }
        k += 1
      }
      A.length = len
      return A
    }
  })()
}




import MicroModal from 'micromodal'; 

import axios from 'axios'
import { stringify } from 'qs'

const axiosInstance = axios.create({
    headers: {
        'X-CSRF-Token': window.csrfToken,
    },
    transformRequest: [
        function(data) {
            return stringify(data)
        },
    ],
})


function submitForm(e) {

    e.preventDefault();
    
    const form = e.target;
    const formData = new FormData(form);
    const hasErrors = false;
    var data = {};

    formData.forEach((value, key) => {data[key] = value});
    //console.log(data);

    form.classList.add('loading');
    
    
    if (hasErrors) {
        var prevErrors = form.querySelectorAll('div.errors');
        for (var i in prevErrors) { prevErrors[i].classList.remove('errors') }
    }
    
    var button = form.querySelector('button[type="submit"]');
    var buttonOg = button.innerHTML;
    button.innerHTML = 'Sending…';
    button.classList.add('submitted');

    var thanks = window.thankyou ? window.thankyou : 'Thank you for your message';

    axiosInstance.post('/?action=sprout-forms/entries/save-entry', data)
    .then(function(response){
        //console.log(response);
        if (response.data.success == true) 
        {
          var el = document.createElement('div');
          el.classList.add('max-w-2xl');
          el.innerHTML = "<h2 class='form-thankyou text-blue leading-tight py-8'>" + thanks + "</h2>";
          form.parentNode.replaceChild(el, form);
        } 
        else 
        {
            form.classList.remove('loading');
            button.classList.remove('submitted');
            button.innerHTML = buttonOg;

            if (response.data.errors) {
                var errors = document.createElement('ul');
                errors.classList.add('errors');
                for (var field in response.data.errors) {
                    errors.innerHTML += '<li>' + response.data.errors[field] + '</li>';
                    var errorfield = form.querySelector("." + field);
                    errorfield.classList.add('errors');
                    errorfield.appendChild(errors);
                }
            } 
        }

    })
    .catch(function(error){
        form.classList.remove('loading');
        button.classList.remove('submitted');
        button.innerHTML = buttonOg;
        alert('Sorry, form not sending at the moment, please try later');
    });
}



// App main
const main = async () => {

    // Async load the vue module
    const Vue = await import(/* webpackChunkName: "vue" */ 'vue');
    const VueCarousel = await import(/* webpackChunkName: "VueCarousel" */ 'vue-carousel');

    // Create our vue instance
    const vm = new Vue.default({
        el: "#content",
        components: {
            'VuePictureSwipe': () => import(/* webpackChunkName: "VuePictureSwipe" */ '../vue/VuePictureSwipe.vue'),
            'Parallax': () => import(/* webpackChunkName: "Parallax" */ '../vue/Parallax.vue'),
            'carousel' : VueCarousel.Carousel,
            'slide' : VueCarousel.Slide,
            'cardfigure' : {
                      template:`<figure :class="{ 'active': toggle }" @mouseover="toggle = !toggle"><slot></slot></figure>`,
                      data() {return {toggle: false}}
                    },
            //'modal' : { template: '#modal-template' }
        },
        data: {
          //showModal: false
        },
        directives : {
            scroll: {
              inserted: function (el, binding) {
                let f = function (evt) {
                  if (binding.value(evt, el)) {
                    window.removeEventListener('scroll', f)
                  }
                }
                window.addEventListener('scroll', f)
              }
            }
        },
        methods: {
            handleScroll: function (evt, el) {
              if (window.scrollY > el.offsetTop + 100 - (window.innerHeight || document.documentElement.clientHeight) ) {
                el.setAttribute(
                  'style',
                  'opacity: 1; transform: translate3d(0, -10px, 1px)'
                )
              }
              return window.scrollY > el.offsetTop
            }
        },
        mounted() {
        },
    });
};


// Execute async function
main().then( (value) => { 

  if (document.querySelectorAll('[data-micromodal-trigger]')) {
      MicroModal.init({
        //onShow: modal => console.info(`${modal.id} is shown`), // [1]
        //onClose: modal => console.info(`${modal.id} is hidden`), // [2]
        //openTrigger: 'data-custom-open', // [3]
        //closeTrigger: 'data-custom-close', // [4]
        disableScroll: true, // [5]
        disableFocus: false, // [6]
        awaitOpenAnimation: false, // [7]
        awaitCloseAnimation: false, // [8]
        //debugMode: true // [9]
      });
  }

  if (document.getElementById('contact-form')) {
      const form = document.getElementById('contact-form')
      form.addEventListener('submit',submitForm);
  }

  if (document.querySelector('.house-gallery')) {
    var gallery = document.querySelector('.house-gallery');
    var featured = document.querySelectorAll('.featured-photos figure'), i;
    for (i = 0; i < featured.length; ++i) {
      featured[i].number = i;
      featured[i].classList.add('cursor-pointer');
      featured[i].addEventListener('click', function(e,i){
        gallery.querySelectorAll('a')[this.number].click();
      });
    }
  }

  if (document.getElementById('let-modal')) {
    MicroModal.show('let-modal');
  }

  

 });




